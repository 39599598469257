import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";

export default {
  data: () => ({
    content: "",
    backUrl: "/static-template",
    staticTemplate: {
      id: 0,
      name: "",
      subject: "",
      description: "",
      body: "",
      group: "",
      use_type: "Single Use",
      for_old_client: false,
    },
    config: {},
    loading: false,
    options: ["Single Use", "Multiple Use"],
  }),

  computed: {},
  components: { Multiselect },

  methods: {
    validateStaticTemplate() {
      this.staticTemplate.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },
    update() {
      let _vm = this;
      this.axios
        .post("/static-templates/" + this.staticTemplate.id, {
          ...this.staticTemplate,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.staticTemplate = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    add() {
      let _vm = this;
      this.axios
        .post("/static-templates", _vm.staticTemplate)
        .then(function (response) {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },

    getDetail() {
      let _vm = this;
      this.axios
        .get("/static-templates/" + this.$route.params.id)
        .then(function (response) {
          _vm.staticTemplate = response.data.data;
        })
        .catch(function () {});
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
  },
};
